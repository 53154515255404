/* eslint-disable no-param-reassign */
import React from 'react';

import { fetchUtils, Admin, Resource } from 'react-admin';

import { CompanyList } from './components/company-list/CompanyList';
import { CrefoLayout } from './components/crefo-layout/CrefoLayout';
import { ImpersonatingsCreate } from './components/impersonatings-create/ImpersonatingsCreate';
import { ImpersonatingsList } from './components/impersonatings-list/ImpersonatingsList';
import { OrderList } from './components/order-list/OrderList';
import { PersonsList } from './components/persons-list/PersonsList';
import { PinGeneration } from './components/pin-generation/PinGeneration';
import { RequestList } from './components/request-list/RequestList';
import { VerifierCreate } from './components/verifier-create/VerifierCreate';
import { VerifierList } from './components/verifier-list/VerifierList';
import { CrefoAuthProvider } from './CrefoAuthProvider';
import { CrefoDataProvider } from './CrefoDataProvider';
import { crefoTheme } from './CrefoTheme';

export function App() {
  const httpClient = (url, options = {}) => {
    if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
    }
    options.user = {
      authenticated: true,
      token: `Bearer ${localStorage.getItem('token')}`,
    };
    return fetchUtils.fetchJson(url, options);
  };

  // TODO: Internationalisierung?
  return (
    <Admin
      authProvider={CrefoAuthProvider}
      title='CrefoTrust Admin Dashboard'
      theme={crefoTheme}
      layout={CrefoLayout}
      dataProvider={CrefoDataProvider(window._env_.API_URL, httpClient)}>
      <Resource
        name='requests'
        options={{ label: 'Anfragen' }}
        list={RequestList}
      />
      <Resource
        name='persons'
        options={{ label: 'Personen' }}
        list={PersonsList}
      />
      <Resource
        name='companies'
        options={{ label: 'Unternehmen' }}
        list={CompanyList}
      />
      <Resource
        name='verifiers'
        options={{ label: 'Verifier' }}
        create={VerifierCreate}
        list={VerifierList}
      />
      <Resource
        name='pins'
        options={{ label: 'Pin Generation' }}
        list={PinGeneration}
      />
      <Resource
        name='impersonatings'
        options={{ label: 'Impersonating' }}
        create={ImpersonatingsCreate}
        list={ImpersonatingsList}
      />
      <Resource name='orders' options={{ label: 'Order' }} list={OrderList} />
    </Admin>
  );
}
